import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';
import { OrderListResponse, PaginatedListResponseOrderListResponse } from '../../services/api-v3';

import { ordersService } from '../../services/services';

export interface IB2COrders<T> extends IAsyncType<T> {
  b2cOrders: T;
  setB2COrders: Dispatch<SetStateAction<T>>;
  setB2COrdersParameters: Dispatch<any[] | null>;
}

const useB2COrders = (
  statusKeys?: string[],
  companyIds?: number[],
  warehouseIds?: number[],
  receiverIds?: number[],
  legalEntityIds?: number[],
  fromDate?: string,
  toDate?: string | undefined,
  withExecutionPercentage?: boolean
): IB2COrders<OrderListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseOrderListResponse>(
      ordersService.getB2COrders,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [
        statusKeys,
        companyIds,
        warehouseIds,
        receiverIds,
        legalEntityIds,
        fromDate,
        toDate,
        withExecutionPercentage
      ]
    );
  return {
    b2cOrders: data?.data ?? [],
    setB2COrders: () => {},
    loading,
    setLoading,
    parameters,
    setParameters,
    setB2COrdersParameters: setParameters
  };
};

export default useB2COrders;
