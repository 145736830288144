import {
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import {
  CompanyListResponse,
  ItemListResponse,
  ItemResponse,
  PaginatedListResponseItemListResponse,
  ReportDeliveryItemExtendedResponse,
  SupplierListResponse,
  WarehouseListResponse
} from '../../../services/api-v3';
import { ItemCategoryResponse, SupplierResponse } from '../../../services/api';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, {
  companyToLookup,
  itemCategoryToLookup,
  itemToLookup,
  warehouseToLookup
} from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { amsV3Service, cookiesService } from '../../../services/services';
import {
  getExtendedCompanyId,
  getExtendedDeliveryId,
  getExtendedItemCategoryId,
  getExtendedSupplierId
} from '../../../helpers/utils';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import {
  useCompanies,
  useItemCategories,
  useReportDeliveryItems,
  useSuppliers,
  useWarehouses
} from '../../../helpers/hooks';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import { AxiosResponse } from 'axios';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateFnsUtils from '@date-io/date-fns';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 8
  },
  searchButton: {
    marginTop: 10,
    marginLeft: 10
  }
}));

const ReportDeliveryItemsComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();

  const { companyIds, warehouseIds, itemId, itemCategoryIds, supplierId } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );
  const filter = cookiesService.getReportDeliveryItemsFilter();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );
  const [filterCompanyIds, setFilterCompanyIds] = useState<any>(
    filter.filterCompanyIds ? filter.filterCompanyIds : []
  );
  const [filterWarehouseIds, setFilterWarehouseIds] = useState<Lookup[]>(
    filter.filterWarehouseIds ? filter.filterWarehouseIds : []
  );
  const [filterSupplierId, setFilterSupplierId] = useState<number | undefined>(
    supplierId ? supplierId : undefined
  );
  const [filterCategories, setFilterCategories] = useState<Lookup[]>(
    filter.filterCategories ? filter.filterCategories : []
  );
  const [filterItemId, setFilterItemId] = useState<number | undefined>(itemId ? itemId : undefined);

  const { suppliers } = useSuppliers();
  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();
  const { categories } = useItemCategories();
  const [items, setItems] = useState<ItemListResponse[]>([]);

  const [supplier, setSupplier] = useState<SupplierResponse | null>(null);
  const [item, setItem] = useState<Lookup | null>(null);

  const { deliveryItems, loading, setParameters } = useReportDeliveryItems(
    filterFromDate,
    filterToDate,
    filterSupplierId,
    companyIds ?? filterCompanyIds.map((c: Lookup) => c.id),
    warehouseIds ?? filterWarehouseIds.map((w: Lookup) => w.id),
    itemCategoryIds ?? filterCategories.map((i: Lookup) => i.id),
    filterItemId
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    const { filterCompanyIds } = cookiesService.getReportStockFilter();

    if (companies) {
      setFilterCompanyIds(
        companyIds
          ? companies
              .filter((c: CompanyListResponse) => companyIds.includes(c.id))
              .map(companyToLookup)
          : filterCompanyIds ?? []
      );
    }
  }, [companyIds, companies]);

  useEffect(() => {
    const { filterWarehouseIds } = cookiesService.getReportStockFilter();

    if (warehouses) {
      setFilterWarehouseIds(
        warehouseIds
          ? warehouses
              .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
              .map(warehouseToLookup)
          : filterWarehouseIds ?? []
      );
    }
  }, [warehouseIds, warehouses]);

  useEffect(() => {
    const { filterCategoriesIds } = cookiesService.getReportStockFilter();

    if (categories) {
      setFilterCategories(
        itemCategoryIds
          ? categories
              .filter((i: ItemCategoryResponse) => itemCategoryIds.includes(i.id))
              .map(itemCategoryToLookup)
          : filterCategoriesIds ?? []
      );
    }
  }, [itemCategoryIds, categories]);

  useEffect(() => {
    const itemIds = [...new Set(deliveryItems.map((deliveryItem: ReportDeliveryItemExtendedResponse) => deliveryItem.itemId))];
    amsV3Service.getItems(itemIds).then((itemsResp: AxiosResponse<PaginatedListResponseItemListResponse>) => {
      setItems(itemsResp.data?.data ?? []);
    })
  }, [deliveryItems]);

  const loadDeliveryItems = useCallback(() => {
    cookiesService.setReportDeliveryItemsFilter({
      filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
      filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
      filterCompanyIds: filterCompanyIds,
      filterWarehouseIds: filterWarehouseIds,
      filterSupplierId: filterSupplierId,
      filterCategories: filterCategories,
      filterItemId: filterItemId
    });
    let params: any = {};
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    if (filterCompanyIds?.length > 0) {
      params.companyIds = filterCompanyIds.map((c: any) => c.id).join(',');
    }
    if (filterWarehouseIds?.length > 0) {
      params.warehouseIds = filterWarehouseIds.map((w) => w.id).join(',');
    }
    if (filterSupplierId) {
      params.supplierId = filterSupplierId;
    }
    if (filterCategories?.length > 0) {
      params.itemCategoryIds = filterCategories.map((c) => c.id).join(',');
    }
    if (filterItemId) {
      params.itemId = filterItemId;
    }
    history.push({
      pathname: '/reports-delivery-item',
      search: new URLSearchParams(params).toString()
    });

    setParameters([
      filterFromDate,
      filterToDate,
      filterSupplierId,
      filterCompanyIds.map((c: Lookup) => c.id),
      filterWarehouseIds.map((w: Lookup) => w.id),
      filterSupplierId,
      filterCategories.map((c: Lookup) => c.id),
      filterItemId
    ]);
  }, [
    history,
    setParameters,
    filterFromDate,
    filterToDate,
    filterSupplierId,
    filterCompanyIds,
    filterWarehouseIds,
    filterCategories,
    filterItemId
  ]);

  const supplierLookup: any = useMemo(
    () =>
      suppliers.reduce((res: any, supplier) => {
        if (!res) {
          res = {};
        }
        res[`${supplier.id}`] = supplier.name;
        return res;
      }, {}),
    [suppliers]
  );

  const itemLookup: any = useMemo(
    () =>
      items.reduce((res: any, item) => {
        if (!res) {
          res = {};
        }
        res[`${item.id}`] = item.name;
        return res;
      }, {}),
    [items]
  );

  const warehouseLookup: any = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse) => {
        if (!res) {
          res = {};
        }
        res[`${warehouse.id}`] = warehouse.name;
        return res;
      }, {}),
    [warehouses]
  );

  const categoryLookup: any = useMemo(
    () =>
      categories.reduce((res: any, category) => {
        if (!res) {
          res = {};
        }
        res[`${category.id}`] = category.name;
        return res;
      }, {}),
    [categories]
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  label="Доставчик"
                  options={suppliers
                    .map((supplier: SupplierListResponse) => ({
                      id: supplier.id,
                      value: `${getExtendedSupplierId(supplier.id)} ${supplier.name}`
                    }))
                    .sort((as1, as2) => as2.id - as1.id)}
                  value={supplier ? supplier : null}
                  onChange={(selectedValue: SupplierResponse | null) => {
                    setSupplier(selectedValue);
                    if (selectedValue) {
                      setFilterSupplierId(selectedValue.id);
                    } else {
                      setFilterSupplierId(undefined);
                    }
                  }}
                  minChar={0}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={companies.map((le) => ({
                    id: le.id,
                    value: `${getExtendedCompanyId(le.id)} ${le.note}`,
                    group: le.name
                  }))}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Компания"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCompanyIds}
                  onChange={(values) => setFilterCompanyIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={(filterCompanyIds.length > 0
                    ? warehouses.filter((wh: WarehouseListResponse) =>
                        filterCompanyIds
                          .map((company: CompanyListResponse) => company.id)
                          .includes(wh.companyId)
                      )
                    : warehouses
                  ).map(warehouseToLookup)}
                  sortOptions={false}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Складове"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterWarehouseIds}
                  onChange={(values) => setFilterWarehouseIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={categories.map((c) => ({
                    id: c.id,
                    value: `${getExtendedItemCategoryId(c.id)} ${c.name}`,
                    group: `${!!c.parentId ? `${c.parentName}` : 'Основни'}`
                  }))}
                  disableCloseOnSelect
                  getOptionLabel={(option: Lookup) => option.value}
                  renderOption={(option: Lookup, { selected }: any) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Категория"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCategories}
                  onChange={(values) => setFilterCategories(values)}
                  groupBy={(g) => g.group}
                />
              </Grid>
              <Grid item lg={5} md={6} sm={12} xs={12}>
                <AMSAsyncAutocomplete
                  label="Артикул"
                  onChange={(item: ItemResponse | null) => {
                    if (item) {
                      setFilterItemId(item?.id);
                      setItem(itemToLookup(item));
                    } else {
                      setFilterItemId(undefined);
                      setItem(null);
                    }
                  }}
                  value={item}
                  minChar={3}
                />
              </Grid>
              <Grid item lg={1} md={6} sm={12} xs={12}>
                <AMSButton
                  color="primary"
                  variant="contained"
                  text="Търсене"
                  loading={loading}
                  disabled={false}
                  onClick={loadDeliveryItems}
                  style={{
                    marginTop: 8,
                    float: 'right'
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <AMSTable
                    title="Доставки на артикули"
                    columns={[
                      {
                        title: 'Дата',
                        field: 'deliveryDate',
                        type: 'date',
                        cellStyle: { width: '10%' },
                        render: (deliveryItem: ReportDeliveryItemExtendedResponse) =>
                          deliveryItem.deliveryDate
                      },
                      {
                        title: 'Доставка №',
                        field: 'deliveryId',
                        cellStyle: { width: '10%' },
                        render: (deliveryItem: ReportDeliveryItemExtendedResponse) => (
                          <AMSLink href={`/delivery?id=${deliveryItem.deliveryId}`}>
                            {getExtendedDeliveryId(deliveryItem.deliveryId)}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Доставчик',
                        field: 'supplierId',
                        lookup: supplierLookup,
                        render: (d: ReportDeliveryItemExtendedResponse) => (
                          <AMSLink href={`/supplier?id=${d.supplierId}`}>
                            {supplierLookup ? supplierLookup[d.supplierId] : 'Зареждане ...'}
                          </AMSLink>
                        ),
                        cellStyle: { width: '16%' }
                      },
                      {
                        title: 'Склад',
                        field: 'warehouseId',
                        lookup: warehouseLookup,
                        render: (d: ReportDeliveryItemExtendedResponse) =>
                          warehouseLookup ? warehouseLookup[d.warehouseId] : 'Зареждане ...',
                        cellStyle: { width: '16%' }
                      },
                      {
                        title: 'Група',
                        field: 'itemCategoryId',
                        lookup: categoryLookup,
                        render: (d: ReportDeliveryItemExtendedResponse) =>
                          categoryLookup ? categoryLookup[d.itemCategoryId] : 'Зареждане ...',
                        cellStyle: { width: '20%' }
                      },
                      {
                        title: 'Артикул',
                        field: 'itemId',
                        lookup: itemLookup,
                        render: (d: ReportDeliveryItemExtendedResponse) => (
                          <AMSLink href={`/item?id=${d.itemId}`}>
                            {itemLookup ? itemLookup[d.itemId] : 'Зареждане ...'}
                          </AMSLink>
                        ),
                        cellStyle: { width: '20%' }
                      },
                      {
                        title: 'Ед. цена',
                        field: 'priceWithoutVat',
                        cellStyle: { width: '6%' },
                        type: 'currency',
                        currencySetting: {
                          locale: 'bg',
                          currencyCode: 'bgn',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      },
                      {
                        title: 'Срок на годност',
                        field: 'expirationDate',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Партида',
                        field: 'batchNumber',
                        cellStyle: { width: '6%' }
                      },
                      {
                        title: 'Държава',
                        field: 'countryOfOrigin',
                        cellStyle: { width: '6%' }
                      },
                      {
                        title: 'Количество',
                        field: 'itemQuantity',
                        cellStyle: { width: '6%' },
                        align: 'right',
                        type: 'numeric'
                      }
                    ]}
                    data={deliveryItems}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
          <AMSConfirmDialog
            open={openConfirmDialog}
            onConfirm={() => {
              loadDeliveryItems();
              setOpenConfirmDialog(false);
            }}
            onClose={() => setOpenConfirmDialog(false)}
            title={'Търсене без филтри!'}
            message={
              'Търсенето без филтри може да доведе до забавяне на системата. Искате да продължите?'
            }
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportDeliveryItemsComponent;
